<template>
  <v-container>
    <v-row
      dense
      class="justify-center"
    >
      <v-col
        xl="6"
        lg="6"
        md="8"
        sm="12"
        xs="12"
        cols="12"
        class="d-flex justify-center white elevation-2"
      >
        <ProductCheckoutInterfaceSelectionDetail
          ref="productCheckoutInterfaceSelectionDetailRef"
          :calculated-vat-tax-percentage="calculatedVatTaxPercentage"
          :pseudoproduct="pseudoproduct"
        />
      </v-col>
    </v-row>

    <v-row
      dense
      class="justify-center"
    >
      <v-col
        xl="6"
        lg="6"
        md="8"
        sm="12"
        xs="12"
        cols="12"
        class="d-flex justify-center flex-column ma-0 pa-0 my-4 elevation-2"
      >
        <StripeCardElement
          ref="stripeCardElementRef"
          :pseudoproduct="pseudoproduct"
          :default-card="defaultCardLast4"
          @card-event="cardEvent"
          @change-card="defaultCardLast4=''"
        />
      </v-col>
    </v-row>

    <v-row
      dense
      class="justify-center"
    >
      <v-col
        xl="6"
        lg="6"
        md="8"
        sm="12"
        xs="12"
        cols="12"
        class="d-flex justify-center ma-0 pa-0"
      >
        <v-btn
          :key="callResult.error"
          class="primary"
          block
          text
          :loading="paymentInProgress"
          :disabled="!callResult.finished || callResult.error !== null || paymentInProgress"
          @click="confirmPayment()"
        >
          {{ $i18n.t('$l.stripe.confirmPayment') }}
        </v-btn>
      </v-col>
    </v-row>

    <ProductCheckoutInterfaceBillingAndValidation
      :call-result="callResult"
      :validation-info-array="validationInfoArray"
      :pseudoproduct="pseudoproduct"
      @billing-validation-completed="billingValidationCompleted"
    />
  </v-container>
</template>

<script>
import ProductCheckoutInterfaceSelectionDetail from './ProductCheckoutInterfaceSelectionDetail'
import ProductCheckoutInterfaceBillingAndValidation from './ProductCheckoutInterfaceBillingAndValidation'
import StripeCardElement from './StripeCardElement'

export default {
  name: 'ProductCheckoutInterface',
  components: {
    ProductCheckoutInterfaceSelectionDetail,
    ProductCheckoutInterfaceBillingAndValidation,
    StripeCardElement
  },
  props: {
    pseudoproduct: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      calculatedVatTaxPercentage: 20,
      defaultCardLast4: '',
      validationInfo: '{}',
      validationInfoArray: [],
      errorCount: -1,
      paymentInProgress: false,
      cardEventObject: null
    }
  },
  watch: {
    validationInfo (newValue, oldValue) {
      this.errorCount = 0
      let validationInfoObject = JSON.parse(this.validationInfo)
      this.validationInfoArray = Array.from([])
      Object.keys(validationInfoObject).forEach(k => {
        if (validationInfoObject[k] != null) {
          let infoObject = validationInfoObject[k]
          this.validationInfoArray.push(infoObject)
          if (infoObject.error) {
            this.errorCount++
          }
        }
      })
    }
  },
  methods: {
    cardEvent (cardEventObject) {
      this.cardEventObject = cardEventObject
      this.pseudoproduct.cardToken = undefined
      let validationInfoObject = JSON.parse(this.validationInfo)
      validationInfoObject.cardError = null
      this.validationInfo = JSON.stringify(validationInfoObject)
      if (cardEventObject && cardEventObject.error) {
        validationInfoObject.cardError = { error: cardEventObject.error.message }
      }
      if (cardEventObject && cardEventObject.complete) {
        this.createToken()
      }
      this.validationInfo = JSON.stringify(validationInfoObject)
    },
    createToken () {
      this.$refs.stripeCardElementRef.createToken()
        .then(r => {
          try {
            this.pseudoproduct.cardToken = r.token.id
          } catch (e) {
            this.callResult.error = this.$i18n.t('$l.error.invalidCard')
          }
        })
    },
    billingValidationCompleted () {
      this.$refs.stripeCardElementRef.enableCardElementPointerEvents()

      this.validationInfo = '{}'
      let validationInfoObject = JSON.parse(this.validationInfo)
      // customer balance info
      if (this.pseudoproduct.stripeCustomerData.customerBalance < 0) {
        validationInfoObject.customerBalance = { info: this.$i18n.t('$l.stripe.balanceInfo') + ` (${(-this.pseudoproduct.stripeCustomerData.customerBalance / 100)} Eur)` }
      }
      // set calcualted tax and stored card
      this.calculatedVatTaxPercentage = this.pseudoproduct.stripeCustomerData.calculatedVatTaxPercentage
      this.defaultCardLast4 = this.pseudoproduct.stripeCustomerData.defaultCardLast4
      this.pseudoproduct.cardToken = undefined
      this.$refs.stripeCardElementRef.clearCard()
      // validate profileForm
      if (!this.pseudoproduct.profileFormValid) {
        this.callResult.error = this.$i18n.t('$l.profile.confirmProfileDataRequired')
      }
      // validate stripeCustomerId
      if (!this.pseudoproduct.stripeCustomerId) {
        this.callResult.error = this.$i18n.t('$l.errorcode.default')
      }
      // validate product subscription already exists
      if (this.pseudoproduct.stripeCustomerData.productSubscriptionAlreadyActive) {
        this.callResult.error = this.$i18n.t('$l.product.customerSubscriptionExists')
      }
      // validate if is investigation plus && activated product has isSuspendedActivation
      if (this.pseudoproduct.category === 'investigation_plus' && this.pseudoproduct.metadata.isActiveProduct && this.pseudoproduct.metadata.isSuspendedActivation) {
        this.callResult.error = this.$i18n.t('$l.product.isSuspendedActivation')
      }
      // validate vat id country
      if (!this.pseudoproduct.stripeCustomerData.taxLocationEqualsToVatCountry && !this.pseudoproduct.stripeCustomerData.taxExempt) {
        this.callResult.error = this.$i18n.t('$l.stripe.taxLocationEqualsToVatCountry')
      }
      // check if we already have payment intent
      if (this.pseudoproduct.stripeCustomerData.productSubscriptionLatestInvoicePaymentIntent) {
        const paymentIntent = JSON.parse(this.pseudoproduct.stripeCustomerData.productSubscriptionLatestInvoicePaymentIntent)
        this.pseudoproduct.paymentIntent = paymentIntent
        this.validatePaymentIntent(paymentIntent, validationInfoObject)
      }
      this.displayEvaluatedCustomerInfo(this.pseudoproduct.stripeCustomerData, validationInfoObject)
      this.validationInfo = JSON.stringify(validationInfoObject)
      this.callResult.finished = true
    },
    validatePaymentIntent (pi, validationInfoObject) {
      if (pi.status === 'requires_action') {
        this.$refs.stripeCardElementRef.confirmCardPayment(pi.client_secret)
          .then(r => {
            if (r.error) {
              if (r.error.code === 'card_declined') {
                validationInfoObject.cardDeclined = { error: this.$i18n.t(`$l.stripe.${r.error.code}`) + r.error.decline_code }
              } else {
                validationInfoObject.cardDeclined = { error: this.$i18n.t(`$l.stripe.${r.error.code}`) }
              }
              this.validationInfo = JSON.stringify(validationInfoObject)
              this.paymentInProgress = false
              this.callResult.finished = true
            } else {
              this.$emit('payment-success')
            }
          })
      } else if (pi.status === 'requires_payment_method') {
        validationInfoObject.requiresPaymentMethod = { error: this.$i18n.t('$l.stripe.requires_payment_method') }
        this.defaultCardLast4 = ''
        this.displayEvaluatedCustomerInfo(this.pseudoproduct.stripeCustomerData, validationInfoObject)
        this.validationInfo = JSON.stringify(validationInfoObject)
        this.paymentInProgress = false
        this.callResult.finished = true
      } else if (pi.status === 'succeeded') {
        this.$emit('payment-success')
      } else {
        validationInfoObject.piUnhandledStatus = { error: this.$i18n.t(pi.status) }
        this.validationInfo = JSON.stringify(validationInfoObject)
        this.paymentInProgress = false
        this.callResult.finished = true
      }
    },
    displayEvaluatedCustomerInfo (stripeCustomerData, validationInfoObject) {
      if (stripeCustomerData.hasVatId) {
        if (stripeCustomerData.vatIdVerificationPending) {
          validationInfoObject.vatIdVerificationPending = { info: this.$i18n.t('$l.stripe.vatIdVerificationPending'), showReloadButton: true }
        } else {
          if (stripeCustomerData.vatIdVerificationOk) {
            validationInfoObject.vatIdVerificationOk = { info: this.$i18n.t('$l.stripe.vatIdVerificationOk') + ' ' + stripeCustomerData.vatIdVerifiedName + ' ' + stripeCustomerData.vatIdVerifiedAddress + ' ' + stripeCustomerData.vatIdVerifiedName + ' ' + stripeCustomerData.vatIdCountry }
          } else {
            validationInfoObject.vatIdVerificationFailed = { info: this.$i18n.t('$l.stripe.vatIdVerificationFailed') }
          }
        }
      }
      if (!stripeCustomerData.hasVatId) {
        if (stripeCustomerData.profileVatIdNotSetToStripe) {
          validationInfoObject.profileVatIdNotSetToStripe = { info: this.$i18n.t('$l.stripe.profileVatIdNotSetToStripe') }
        }
      }
      if (stripeCustomerData.taxExempt) {
        validationInfoObject.taxExempt = { info: this.$i18n.t('$l.stripe.taxExempt') }
      }
      if (stripeCustomerData.taxReverse) {
        validationInfoObject.taxReverse = { info: this.$i18n.t('$l.stripe.taxReverse') }
      }
    },
    confirmPayment () {
      this.validationInfo = '{}'
      let validationInfoObject = JSON.parse(this.validationInfo)
      this.validateAll(validationInfoObject)
      // next tick because we have to wait for the watcher to rebuild validationInfoArray
      this.$nextTick(() => {
        if (!(this.errorCount > 0)) {
          this.paymentInProgress = true
          this.pseudoproduct.selectedPriceId = this.$refs.productCheckoutInterfaceSelectionDetailRef.priceAndQuantitySelection.price.id
          this.pseudoproduct.selectedQuantity = this.$refs.productCheckoutInterfaceSelectionDetailRef.priceAndQuantitySelection.quantity
          this.$xapi.post('endpointsStripePayment/processCardPayment', this.pseudoproduct)
            .then(r => {
              if (r.data && r.data.status) {
                this.validatePaymentIntent(r.data, validationInfoObject)
              } else {
                this.$emit('payment-success')
              }
            })
            .catch(e => {
              if (e.response) {
                validationInfoObject.default = e.response.localizedMessage
              } else {
                validationInfoObject.notResponse = e.response.localizedMessage
              }
              this.validationInfo = JSON.stringify(validationInfoObject)
              this.paymentInProgress = false
            })
        }
      })
    },
    validateAll (validationInfoObject) {
      this.userChoiceValid = this.$refs.productCheckoutInterfaceSelectionDetailRef.validateUserChoice()
      if (!this.userChoiceValid) {
        validationInfoObject.priceOrQuantityNotSelected = { error: this.$i18n.t('$l.stripe.priceOrQuantityNotSelected') }
      }
      if (this.cardEventObject !== null && this.cardEventObject.error) {
        validationInfoObject.cardError = { error: this.cardEventObject.error.message }
      } else {
        if (!this.pseudoproduct.cardToken) {
          if (!this.defaultCardLast4 || this.defaultCardLast4 === '') {
            validationInfoObject.cardError = { error: this.$i18n.t('$l.stripe.inputCardNumber') }
          }
        }
      }
      this.displayEvaluatedCustomerInfo(this.pseudoproduct.stripeCustomerData, validationInfoObject)
      this.validationInfo = JSON.stringify(validationInfoObject)
    }
  }
}
</script>
