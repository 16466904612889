<template>
  <ProductCheckoutWrapper />
</template>

<script>
import ProductCheckoutWrapper from '@/components/servicesCheckout/ProductCheckoutWrapper'

export default {
  name: 'ProductCheckout',
  components: {
    ProductCheckoutWrapper
  }
}
</script>
