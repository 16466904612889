<template>
  <v-container
    fluid
  >
    <app-api-call
      class="mb-3"
      :call-result="callResult"
    />

    <v-row>
      <v-col>
        <ProfileForm
          :key="profileDataKey"
          ref="profileForm"
          :profile-data="userProfileData.profileData"
          :disabled="!inEdit"
        />
      </v-col>

      <v-col
        :cols="$vuetify.breakpoint.smAndDown ? 2 : 1"
        class="text-center ma-0 pa-0"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div class="my-3">
              <v-btn
                class="primary"
                fab
                text
                small
                :disabled="inEdit"
                @click="inEdit = !inEdit"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
          {{ '$l.app.edit' | translate }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div class="my-3">
              <v-btn
                color="info"
                fab
                elevation="0"
                small
                :disabled="!inEdit"
                @click="saveUserProfile"
                v-on="on"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </div>
          </template>
          {{ '$l.app.save' | translate }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div class="my-3">
              <v-btn
                color="error"
                fab
                elevation="0"
                small
                :disabled="!inEdit"
                @click="deleteUserProfile"
                v-on="on"
              >
                <v-icon>mdi-content-cut</v-icon>
              </v-btn>
            </div>
          </template>
          {{ '$l.app.delete' | translate }}
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProfileForm from './ProfileForm.vue'

export default {
  name: 'BillingData',
  components: {
    ProfileForm
  },
  props: {
    forceConfirm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      inEdit: false,
      userData: { },
      userProfileData: { profileData: {} },
      profileDataKey: '',
      forceConfirmFlag: false
    }
  },
  created () {
    this.loadUserData()
    this.forceConfirmFlag = this.forceConfirm
  },
  methods: {
    loadUserData () {
      this.callResult.error = null
      this.callResult.finished = false
      this.$xapi.get('endpointsUserProfile/getSingleUser')
        .then(r => {
          this.dataReceived(r.data)
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    },
    saveUserProfile () {
      if (this.isProfileFormValid()) {
        this.callResult.error = null
        this.callResult.finished = false
        this.userData.amloeUserProfile.data = JSON.stringify(this.userProfileData)
        this.userData.amloeUserProfile.businessId = this.userProfileData.profileData.billingIco
        this.$xapi.put('endpointsUserProfile/billingData', this.userProfileData.profileData)
          .then(r => {
            this.inEdit = false
            this.dataReceived(r.data)
          })
          .catch(e => {
            this.callResult.error = e.response.localizedMessage
          })
          .finally(() => {
            this.callResult.finished = true
          })
      }
    },
    reloadProfileData () {
      this.saveUserProfile()
    },
    deleteUserProfile () {
      this.callResult.error = null
      this.callResult.finished = false
      this.userProfileData.profileData = {
        billingIco: ''
      }
      this.userData.amloeUserProfile.data = JSON.stringify(this.userProfileData)

      this.$xapi.put('endpointsUserProfile/billingData', {})
        .then(r => {
          this.inEdit = false
          this.dataReceived(r.data)
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    },
    isProfileFormValid () {
      let valid = this.$refs.profileForm.validateProfileForm()
      if (!valid) {
        this.callResult.error = this.$i18n.t('$l.profile.profileDataNotComplete')
      }
      return valid
    },
    dataReceived (data) {
      if (data) {
        this.userData = data
        this.userData.email = JSON.parse(this.userData.data).email
        this.userProfileData = JSON.parse(this.userData.amloeUserProfile.data)
      }
      this.profileDataKey = Date.now()
      // next tick because we have to wait for rebuilding profileForm component after data changed
      this.$nextTick(() => {
        let valid = this.isProfileFormValid()
        if (this.forceConfirmFlag && !valid) {
          this.inEdit = true
          this.forceConfirmFlag = false
        }
        this.$emit('received-billing-data', this.userData)
      })
    }
  }
}
</script>
