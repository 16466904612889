<template>
  <v-container
    fluid
  >
    <app-api-call
      :call-result="callResult"
      class="mt-4"
    />

    <transition
      mode="out-in"
      :css="false"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <component
        :is="visibleComponent"
        v-if="callResult.finished && !callResult.error"
        :pseudoproduct="pseudoproduct"
        @payment-success="visibleComponent = 'ProductCheckoutOutcome'"
      />
    </transition>
  </v-container>
</template>

<script>
import ProductCheckoutInterface from './ProductCheckoutInterface'
import ProductCheckoutOutcome from './ProductCheckoutOutcome'

import Velocity from 'velocity-animate'

export default {

  name: 'ProductCheckoutWrapper',
  components: {
    ProductCheckoutInterface,
    ProductCheckoutOutcome
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      visibleComponent: 'ProductCheckoutInterface',
      pseudoproduct: {}
    }
  },
  mounted () {
    this.$xapi.get(`endpointsStripeProduct/pseudoproduct?pseudoproductId=${this.$route.params.pseudoproductId}`)
      .then(r => {
        this.pseudoproduct = r.data
      })
      .catch(e => {
        this.callResult.error = e.response.localizedMessage
      })
      .finally(() => {
        this.callResult.finished = true
      })
  },
  methods: {
    beforeEnter (el) {
      el.style.opacity = 0
    },
    enter (el, done) {
      Velocity(el,
        { opacity: 1, top: '16px' },
        { duration: 300, easing: 'easeIn', complete: done })
    },
    beforeLeave (el) {
      el.style.opacity = 1
      el.style.position = 'absolute'
      el.style.top = '0px'
    },
    leave (el, done) {
      Velocity(el,
        { opacity: 0, top: '-50%' },
        { duration: 250, easing: 'easeOut', complete: done })
    }
  }
}
</script>
