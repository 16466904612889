<template>
  <div>
    <v-row>
      <v-col>
        <CompanyAutocomplete
          :disabled="disabled"
          @on-selection="companySelected"
          @loading-address="setLoadingAddress"
        />
        <v-col />
      </v-col>
    </v-row>
    <v-form
      ref="profileForm"
      :key="formKey"
      lazy-validation
      class="ma-0 pa-0"
    >
      <v-overlay
        :value="overlay"
        absolute
      >
        <v-progress-circular

          color="primary"
          indeterminate
          size="40"
          width="5"
          class="mr-3"
        />
      </v-overlay>
      <v-row
        :dense="dense"
      >
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="profileData.billingName"
            name="name"
            :label="'$l.settings.billingName' | translate"
            :rules="[v => !!v || $i18n.t('$l.app.required')]"
            required
            maxlength="64"
            counter
            :disabled="disabled"
            :dense="dense"
            :outlined="outlined"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="profileData.billingCompany"
            name="organization"
            :label="'$l.settings.billingCompany' | translate"
            :rules="[v => !!v || $i18n.t('$l.app.required')]"
            required
            maxlength="64"
            counter
            :disabled="disabled"
            :dense="dense"
            :outlined="outlined"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="profileData.billingPhone"
            name="phone"
            :label="'$l.settings.billingPhone' | translate"
            :disabled="disabled"
            :dense="dense"
            :outlined="outlined"
            maxlength="32"
            counter
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="profileData.billingIco"
            name="id"
            :label="'$l.settings.billingIco' | translate"
            :disabled="disabled"
            :dense="dense"
            :outlined="outlined"
            :rules="billingIcoRules"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="profileData.billingDic"
            name="dic id"
            :label="'$l.settings.billingDic' | translate"
            :disabled="disabled"
            :dense="dense"
            :outlined="outlined"
            maxlength="12"
            counter
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="profileData.billingTaxId"
            name="vat id"
            :label="'$l.settings.billingTaxId' | translate"
            :disabled="disabled"
            :dense="dense"
            :outlined="outlined"
            maxlength="20"
            counter
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="profileData.billingStreetLine"
            name="street address"
            :label="'$l.settings.billingStreetLine' | translate"
            :rules="[v => !!v || $i18n.t('$l.app.required')]"
            required
            :disabled="disabled"
            :dense="dense"
            :outlined="outlined"
            maxlength="64"
            counter
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="profileData.billingCity"
            name="city"
            :label="'$l.settings.billingCity' | translate"
            :rules="[v => !!v || $i18n.t('$l.app.required')]"
            required
            :disabled="disabled"
            :dense="dense"
            :outlined="outlined"
            maxlength="32"
            counter
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="profileData.billingZipPostalCode"
            name="postal code"
            :label="'$l.settings.billingZipPostalCode' | translate"
            :rules="[v => !!v || $i18n.t('$l.app.required')]"
            required
            :disabled="disabled"
            :dense="dense"
            :outlined="outlined"
            maxlength="10"
            counter
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            :key="$i18n.locale"
            v-model="profileData.billingTaxLocation"
            :items="$store.getters.getCountries"
            :label="$i18n.t('$l.settings.billingTaxLocation')"
            :filter="countryFilter"
            :item-value="item => item.iso3166_1"
            :item-text="'name'"
            :rules="[v => !!v || $i18n.t('$l.app.required')]"
            required
            :disabled="disabled"
            :dense="dense"
            :outlined="outlined"
            autocomplete="new-password"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import CompanyAutocomplete from '../investigation/processCase/stepper/legalEntity/CompanyAutocomplete.vue'

export default {
  name: 'ProfileForm',
  components: {
    CompanyAutocomplete
  },
  props: {
    profileData: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {

      myString: '123a',
      formKey: 0,
      loadingCompanyAddress: false,
      overlay: false,
      address: null,
      billingIcoRules: [
        value => {
          if (!value) return this.$i18n.t('$l.app.required')
          if (!/^\d+$/.test(value)) return this.$i18n.t('$l.app.validationNumeric')
          if (value.length !== 8) return this.$i18n.t('$l.app.validationLength8digits')
          return true
        }
      ]
    }
  },
  methods: {
    countryFilter (item, queryText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.iso3166_1.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1 || textOne.normalize('NFD').replace(/[\u0300-\u036F]/g, '').indexOf(searchText) > -1
    },
    validateProfileForm () {
      return this.$refs.profileForm.validate()
    },
    companySelected (company) {
      this.reserFormData()

      this.profileData.billingStreetLine = company.address.replace(' ' + company.zipcode, '')// remove zip code from address

      this.profileData.billingTaxLocation = company.country
      this.profileData.billingCompany = company.name
      this.profileData.billingIco = company.ico
      this.profileData.billingTaxId = company.icdph
      this.profileData.billingZipPostalCode = company.zipcode
      this.formKey = Date.now()
    },
    setLoadingAddress (loadingAddress) {
      this.loadingCompanyAddress = loadingAddress
      this.overlay = loadingAddress
    },
    reserFormData () {
      this.profileData.billingTaxLocation = null
      this.profileData.billingZipPostalCode = null
      this.profileData.billingCity = null
      this.profileData.billingStreetLine = null
      this.profileData.billingTaxId = null
      this.profileData.billingDic = null
      this.profileData.billingIco = null
      this.profileData.billingPhone = null
      this.profileData.billingCompany = null
      this.profileData.billingName = null
    }
  }
}
</script>
