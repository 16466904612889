<template>
  <div>
    <div
      v-if="defaultCard !== ''"
      class="ma-2"
    >
      <div
        class="body-2 info1--text"
      >
        {{ $i18n.t('$l.stripe.defaultCardInfo') }}
      </div>
      <v-btn
        class="info1 mt-1"
        text
        small
        dark
        @click="$emit('change-card')"
      >
        {{ $i18n.t('$l.stripe.defaultCardChange') }}
      </v-btn>
    </div>
    <v-card
      style="border-radius: 0em;"
    >
      <div
        id="card-element"
        :style="{'pointer-events' : cardElementPointerEvents}"
      >
        <!-- A Stripe Element will be inserted here. -->
      </div>
      <v-icon
        style="position: absolute; right: 8px; top: 8px"
        @click="clearCard()"
      >
        mdi-delete
      </v-icon>
      <v-overlay
        v-if="defaultCard !== ''"
        absolute
        z-index="0"
      >
        {{ '**** **** **** ' + defaultCard }}
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'StripeCardElement',
  props: {
    pseudoproduct: {
      type: Object,
      required: true
    },
    defaultCard: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      stripe: null,
      card: null,
      cardElementPointerEvents: 'none',
      style: {
        base: {
          color: '#223344',
          fontSize: '16px'
        },
        invalid: {
          color: '#e76b6a',
          iconColor: '#e76b6a'
        }
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(this.pseudoproduct.metadata.publicKey, { locale: this.$i18n.locale })
      this.card = this.stripe.elements().create('card', { hidePostalCode: true, style: this.style })
      this.card.mount('#card-element')
      this.card.on('change', this.cardEvent)
    },
    cardEvent (cardEvent) {
      this.$emit('card-event', cardEvent)
    },
    createToken () {
      return this.stripe.createToken(this.card)
    },
    confirmCardPayment (secret) {
      return this.stripe.confirmCardPayment(secret)
    },
    clearCard () {
      return this.card.clear()
    },
    enableCardElementPointerEvents () {
      this.cardElementPointerEvents = 'auto'
    }
  }
}
</script>

<style lang="scss" scoped>
  .StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 12px;
    padding-right: 28px;
    border: 1px solid transparent;
    border-radius: 0px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
</style>
