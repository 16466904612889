<template>
  <v-container
    fluid
  >
    <v-row
      dense
      class="justify-center"
    >
      <v-col
        xl="6"
        lg="6"
        md="8"
        sm="12"
        xs="12"
        cols="12"
        class="justify-center"
      >
        <v-card-text
          class="text-h6 text-center"
        >
          <v-avatar
            color="success"
            size="48"
          >
            <v-icon
              class="white--text"
              x-large
            >
              mdi-check
            </v-icon>
          </v-avatar>
        </v-card-text>

        <v-card-text
          class="text-h6 text-center secondary--text"
        >
          {{ $i18n.t('$l.services.paymentSuccess') }}
        </v-card-text>

        <v-card-text
          class="body-1 text-center secondary--text"
        >
          {{ $i18n.t('$l.services.paymentSuccessActivationInfo') }}
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ProductCheckoutOutcome'
}
</script>
