<template>
  <div>
    <v-row
      dense
      class="justify-center"
    >
      <v-col
        xl="6"
        lg="6"
        md="8"
        sm="12"
        xs="12"
        cols="12"
        class="ma-0 pa-0 my-3"
      >
        <app-api-call
          :call-result="callResult"
          class="my-1"
        />
        <template v-for="(item, index) in validationInfoArray">
          <app-api-call
            v-if="item !== null && item.error"
            :key="index"
            :call-result="{finished: true, error: item.error}"
            class="my-1"
          />
          <app-api-call
            v-if="item !== null && item.info"
            :key="index"
            :call-result="{finished: true, info: item.info}"
            class="my-1"
          >
            <v-btn
              v-if="item !== null && item.showReloadButton"
              small
              class="info"
              text
              block
              @click="$refs.billingData.reloadProfileData()"
            >
              <v-icon
                class="mr-2"
                small
              >
                mdi-reload
              </v-icon>
              {{ $i18n.t('$l.app.reload') }}
            </v-btn>
          </app-api-call>
        </template>
      </v-col>
    </v-row>

    <v-row
      dense
      class="justify-center"
    >
      <v-col
        xl="6"
        lg="6"
        md="8"
        sm="12"
        xs="12"
        cols="12"
        class="d-flex justify-center ma-0 pa-0 elevation-2"
      >
        <v-expansion-panels
          v-model="openPanel"
          multiple
        >
          <v-expansion-panel
            class="elevation-0"
          >
            <v-expansion-panel-header>
              {{ $i18n.t('$l.settings.billingData') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <BillingData
                ref="billingData"
                :force-confirm="true"
                @received-billing-data="receivedBillingData"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BillingData from '../settings/BillingData'

export default {
  name: 'ProductCheckoutInterfaceBillingAndValidation',
  components: {
    BillingData
  },
  props: {
    callResult: {
      type: Object,
      required: true
    },
    validationInfoArray: {
      type: Array,
      required: true
    },
    pseudoproduct: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      openPanel: [0]
    }
  },
  methods: {
    receivedBillingData (userData) {
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.post('endpointsUserProfile/getSingleUserStripeCustomer', this.pseudoproduct.stripeProductId)
        .then(r => {
          const stripeCustomerData = r.data
          this.pseudoproduct.stripeCustomerData = stripeCustomerData
          this.pseudoproduct.stripeCustomerId = userData.stripeCustomerId
          this.pseudoproduct.profileFormValid = this.$refs.billingData.isProfileFormValid()
          this.$emit('billing-validation-completed')
        })
        .catch(e => {
          this.callResult.finished = true
          this.callResult.error = e.response.localizedMessage
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {.v-expansion-panel-content__wrap {
    padding: 8px !important;

  }}
  .v-expansion-panel::before {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
</style>
