<template>
  <v-container
    class="ma-0 pa-0"
    fluid
  >
    <v-card-title
      class="ma-0 pa-3"
    >
      <v-icon
        color="info"
        class="mr-3"
      >
        mdi-package-variant
      </v-icon>
      <span
        v-if="pseudoproduct.name"
      >
        {{ $i18n.t(`$l.product.${pseudoproduct.name}`) }}
      </span>
      <span
        v-else
        class="error--text text-caption"
      >
        {{ $i18n.t('$l.errorcode.default') }}
      </span>
    </v-card-title>

    <v-radio-group
      v-if="pseudoproduct.category === 'package'"
      v-model="priceAndQuantitySelection.price"
      class="ma-3 my-0"
    >
      <v-radio
        v-for="(price, idx) in prices"
        :key="idx"
        :label="getPriceLabel(price)"
        :value="price"
      />
    </v-radio-group>

    <v-select
      v-if="pseudoproduct.category === 'investigation_plus'"
      v-model="priceAndQuantitySelection.quantity"
      :items="[1, 2, 3, 5, 8, 10, 15, 20, 30, 50, 100, 200, 500]"
      :label="$i18n.t('$l.services.selectAmount')"
      class="ma-0 pa-0 mx-3 mt-2"
      outlined
      dense
    />

    <v-divider class="ma-3 mt-0" />

    <span
      :key="priceAndQuantitySelectionChangeTS"
    >
      <basic-resp-row
        left="$l.services.vatExcluded"
        :right="$utils.formatCurrency(getPriceAmount())"
        width="100%"
        rowcss="body-2 mx-3 info1--text"
      />
      <basic-resp-row
        left="$l.services.vatPrice"
        :right="$utils.formatCurrency(getVatDue())"
        width="100%"
        rowcss="body-2 mx-3 info1--text"
      />
      <basic-resp-row
        left="$l.services.totalPrice"
        :right="$utils.formatCurrency(getTotal())"
        width="100%"
        rowcss="body-2 mx-3 info1--text"
      />
    </span>

    <v-card-text
      v-if="pseudoproduct.category === 'package'"
      class="ma-0 pa-3 text-caption"
    >
      {{ $i18n.t('$l.services.subscriptionNote') }}
    </v-card-text>
  </v-container>
</template>

<script>
export default {
  name: 'ProductCheckoutInterfaceSelectionDetail',
  props: {
    pseudoproduct: {
      type: Object,
      required: true
    },
    calculatedVatTaxPercentage: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      priceAndQuantitySelection: {
        price: null,
        quantity: 1
      },
      priceAndQuantitySelectionChangeTS: ''
    }
  },
  computed: {
    prices () {
      return JSON.parse(this.pseudoproduct.metadata.prices)
    }
  },
  mounted () {
    this.priceAndQuantitySelection.price = this.prices[0]
  },
  methods: {
    getPriceAmount () {
      return this.priceAndQuantitySelection.price ? this.priceAndQuantitySelection.quantity * this.priceAndQuantitySelection.price.unit_amount / 100 : 0
    },
    getVatDue () {
      let amount = this.priceAndQuantitySelection.price ? this.priceAndQuantitySelection.quantity * this.priceAndQuantitySelection.price.unit_amount / 100 : 0
      let vat = this.calculatedVatTaxPercentage ? this.calculatedVatTaxPercentage / 100 : 0
      return amount * vat
    },
    getTotal () {
      let amount = this.priceAndQuantitySelection.price ? this.priceAndQuantitySelection.quantity * this.priceAndQuantitySelection.price.unit_amount / 100 : 0
      let vat = this.calculatedVatTaxPercentage ? this.calculatedVatTaxPercentage / 100 : 0
      return amount + amount * vat
    },
    validateUserChoice () {
      return this.priceAndQuantitySelection.price !== null && this.priceAndQuantitySelection.quantity > 0
    },
    getPriceLabel (price) {
      return this.$utils.formatCurrency(price.unit_amount / 100) + ' ' + this.$i18n.t('$l.services.vatExcluded') + ' ' + this.$i18n.t(`$l.stripe.recuringInterval${price.recurring.interval}`)
    }
  }
}
</script>
